<template>
  <div
    class="ui-input"
    :class="stateClasses"
    data-testid="elem_vrp_000929"
    @click="changeFocus(true)"
    @focus="changeFocus(true)"
    @blur="changeFocus(false)"
  >
    <div class="ui-input__area" data-testid="elem_vrp_000930">
      <ui-icon
        v-if="isSearch"
        name="Linear/Search/Magnifer"
        class="ui-input__prepend-icon"
        data-testid="elem_vrp_000931"
        @click="clearSearch"
      />

      <label v-if="label && !hideLabel" :for="fieldId" class="ui-input__label" data-testid="elem_vrp_000932">
        {{ label }}
      </label>

      <div class="ui-input__field-area" data-testid="elem_vrp_000933">
        <input
          :id="fieldId"
          :type="inputType"
          ref="inputElement"
          class="ui-input__field"
          :disabled="disabled"
          v-model="field.value.value"
          v-bind="$attrs"
          :class="{
            '--with-action': isPassword,
          }"
          :placeholder="inputPlaceholder"
          :readonly="readonly || isDatePicker"
          data-testid="elem_vrp_000934"
          @input="field.setValue(field.value.value, false)"
          @change="field.handleChange($event, true)"
          @focus="changeFocus(true, $event)"
          @blur="changeFocus(false, $event)"
          @click="onClick"
        />

        <ui-icon
          v-if="isSearch && field.value.value.length"
          name="Linear/Custom/Close"
          class="ui-input__append-icon ui-input__append-icon--close"
          @click="clearSearch"
        />

        <ui-icon
          v-else-if="isPassword"
          :name="isPasswordShow ? 'Linear/Security/Eye' : 'Linear/Security/Eye Closed'"
          class="ui-input__append-icon"
          @click="togglePasswordShow"
        />
      </div>

      <slot name="append"></slot>
    </div>

    <div v-if="field.errors.value.length" class="ui-input__errors" data-testid="elem_vrp_000935">
      <p
        v-for="error in [field.errors.value[field.errors.value.length - 1]]"
        :key="error"
        class="ui-input__error-item"
        data-testid="elem_vrp_000936"
      >
        {{ error }}
      </p>
    </div>

    <div v-if="helpText" class="ui-input__help" data-testid="elem_vrp_000937">
      <p class="ui-input__help-item" data-testid="elem_vrp_000938">{{ helpText }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'

interface Props {
  path: string
  containerClass?: string
  type?: string
  label?: string
  required?: boolean
  hideLabel?: boolean
  disabled?: boolean
  helpText?: string
  readonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  label: '',
  hideLabel: false,
  required: false,
  disabled: false,
  helpText: '',
  containerClass: '',
  readonly: false,
})

const {
  type,
  label,
  hideLabel,
  required,
  disabled,
  helpText,
  path,
  containerClass,
  readonly,
} = toRefs(props)

const emit = defineEmits<{
  'click': [event: Event]
}>()

const slots = useSlots()

const isSlot = computed(() => {
  return {
    append: !!slots.append,
  }
})

const fieldId = useId()

const field = useField<string>(path, undefined)

const inputElement = ref(null)
const isFocus = ref(false)
const changeFocus = (value: boolean, evt: Event | null = null) => {
  if (disabled.value || readonly.value                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ) {
    return
  }
  isFocus.value = value
  if (value) {
    nextTick(() => {
      inputElement.value?.focus()
    })
  }
  if (evt && !value) {
    field.handleBlur(evt, true)
  }
}

const isSearch = computed(() => {
  return type.value === 'search'
})
const clearSearch = () => {
  field.setValue('', false)
}

const isPassword = computed(() => {
  return type.value === 'password'
})

const isCustomTip = computed(() => {
  return type.value === 'custom-tip'
})

const isDatePicker = computed(() => {
  return type.value === 'date'
})

const onClick = (event: Event) => {
  if (isDatePicker.value) {
    emit('click', event)
  }
}

const isPasswordShow = ref(false)
const togglePasswordShow = () => {
  isPasswordShow.value = !isPasswordShow.value
}

const stateClasses = computed(() => {
  return {
    '--focused': isFocus.value,
    '--active': isFocus.value || !!field.value.value,
    '--disabled': disabled.value,
    '--required': required.value,
    '--with-errors': !!field.errors.value.length,
    '--with-help-text': !!helpText.value,
    '--with-search': isSearch.value,
    '--with-tip': isCustomTip.value,
    '--with-readonly': readonly.value,
    '--slot-append': isSlot.value.append,
    [containerClass.value]: true,
  }
})

const inputType = computed(() => {
  if (isPassword.value) {
    return isPasswordShow.value ? 'text' : 'password'
  }
  if (isSearch.value) {
    return 'text'
  }
  if (isCustomTip.value) {
    return 'number'
  }
  return type.value
})

const inputPlaceholder = computed(() => {
  if (isSearch.value || isCustomTip.value) {
    return label.value
  }
  return ''
})
</script>

<style lang="scss">
.ui-input {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  &__area {
    padding: 16rem 14rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 56rem;
    height: 56rem;
    background: map-get($grayPalette, 'color_28');
    border-radius: 10rem;
  }

  &__label {
    user-select: none;
    color: map-get($grayPalette, 'color_9b');
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem;
    background: transparent;
    transition: all 200ms;
  }

  &__field-area {
    width: 100%;
    display: none;
    opacity: 0;
    transition: all 200ms;
    justify-content: space-between;
    align-items: center;
    gap: 0 8rem;
  }

  &__field {
    width: 100%;
    color: map-get($grayPalette, 'color_ff');
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem;
    background: transparent;
    border: unset;
    outline: unset;
    box-shadow: unset;

    &.--with-action {
      width: calc(100% - 28rem);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px map-get($grayPalette, 'color_28') inset !important;
      -webkit-text-fill-color: map-get($grayPalette, 'color_ff') !important;
      -webkit-background-clip: text;
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
      -webkit-transition-delay: 9999s;
      caret-color: white;
      transition: background-color 5000s ease-in-out 0s;
    }

    &:-webkit-autofill::first-line {
      font-size: 16rem;
    }
  }

  &__prepend-icon {
    content: '';
    position: absolute;
    left: 14rem;
    top: 20rem;
    bottom: 20rem;
    color: map-get($grayPalette, 'color_9b') !important;
    font-size: 16rem;
  }

  &__append-icon {
    position: absolute;
    right: 14rem;
    top: 18rem;
    bottom: 18rem;
    font-size: 20rem;
    color: map-get($grayPalette, 'color_9b') !important;
    cursor: pointer;
    z-index: 5;
  }

  &__error-item {
    margin: 2rem 14rem 0;
    color: map-get($colorPalette, 'color_ff6');
    font-size: 14rem;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;
  }

  &__help-item {
    margin: 2rem 14rem 0;
    color: map-get($grayPalette, 'color_9b');
    font-size: 14rem;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;
  }

  &.--focused,
  &.--active {
    .ui-input__prepend-icon {
      color: map-get($grayPalette, 'color_ff') !important;
    }
    .ui-input__label {
      font-size: 12rem;
      line-height: 16rem;
      letter-spacing: 0.24rem;
    }
    .ui-input__field-area {
      opacity: 1;
      display: flex;
    }
  }

  &.--disabled {
    cursor: default;

    .ui-input__label {
      color: map-get($grayPalette, 'color_62');
    }
    .ui-input__field {
      cursor: default;
      color: map-get($grayPalette, 'color_62');
    }
  }

  &.--with-errors {
    .ui-input__area {
      border: 1px solid map-get($colorPalette, 'color_ff6');
    }
    .ui-input__field {
      color: map-get($colorPalette, 'color_ff6');
    }
  }

  &.--with-errors:not(.--active) {
    .ui-input__label {
      color: map-get($colorPalette, 'color_ff6');
    }
  }

  &.--required {
    .ui-input__label {
      position: relative;

      &::after {
        content: '*';
        padding-left: 6rem;
        display: inline-block;
        color: map-get($colorPalette, 'color_ff6');
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24rem;
        transition: font-size 200ms;
      }
    }
  }

  &.--focused.--required,
  &.--active.--required {
    .ui-input__label {
      &::after {
        font-size: 12rem;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.24rem;
      }
    }
  }

  &.--disabled.--required {
    .ui-input__label {
      &::after {
        color: map-get($grayPalette, 'color_62');
      }
    }
  }

  &.--with-search {
    &.--focused,
    &.--active {
      .ui-input__label {
        opacity: 0;
        display: none;
      }
    }

    .ui-input__append-icon svg {
      color: map-get($colorPalette, 'color_ff6');
    }
    .ui-input__field, .ui-input__label {
      padding-left: 22rem;
    }
  }

  &.--with-tip {
    .ui-input__field-area {
      opacity: 1;
      display: flex;
    }
    .ui-input__errors {
      display: none;
    }
  }

  &.--with-readonly {
    cursor: not-allowed;

    .ui-input__field {
      cursor: not-allowed;
    }
  }

  &.--slot-append {
    .ui-input__append-icon {
      color: white;
    }
    .ui-input__area {
      padding-right: 48rem !important;
    }
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
